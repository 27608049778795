import React from 'react';
import { MdPlace } from 'react-icons/md';

import { colors } from '@nucleos/theme';

import { Container } from './styles';

export default function Card({ id, event, place, day, weekDay, city, state }) {
  return (
    <Container
      to={{
        pathname: `/resultados/${id}`,
        state: {
          event,
          city,
          state,
          page: 'Resultados',
        },
      }}
    >
      <div className="left">
        <p>{event}</p>

        <div>
          <MdPlace size={12} color={colors.primary} />

          <p>{place}</p>
        </div>
      </div>

      <div className="right">
        <strong>{day}</strong>

        <p>{weekDay}</p>
      </div>
    </Container>
  );
}
