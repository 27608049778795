import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import media from 'styled-media-query';
import { colors, fonts, metrics } from '@nucleos/theme';

export const Container = styled.div`
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('large')`
    display: ${props => (props.mobileHide ? 'none' : 'block')};

    ${props =>
      props.suspend &&
      css`
        position: absolute;
        top: 0;
        left: ${metrics.containerPadding}px;
      `}
  `}

  > hgroup {
    > h1 {
      line-height: 36px;
      letter-spacing: -2px;
      text-transform: uppercase;
      font-size: ${fonts.title}px;
      font-family: GoogleSans-Bold, sans-serif;
      color: ${props => props.theme.primaryLighten};

      ${media.lessThan('large')`
        padding-right: 5px;

        font-size: ${fonts.size_4}px;
      `}

      ${media.lessThan('medium')`
        line-height: 24px;
        letter-spacing: -0.95px;
      `}
    }

    > h2 {
      letter-spacing: 0.38px;
      font-weight: normal;
      font-size: ${fonts.size_4}px;
      color: ${props => props.theme.primaryLighten};

      ${media.lessThan('large')`
        line-height: 17px;
        letter-spacing: -.26px;
        font-size: ${fonts.size_1}px;
      `}
    }
  }
`;

export const Back = styled(Link)`
  height: 40px;
  padding: 0 20px;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  background: #fff;
  line-height: 40px;
  color: ${colors.black};

  ${media.lessThan('large')`
    display: none;
  `}
`;
