import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header as HeaderComponent } from '@nucleos/components';

import { setMenuAsOpen, setMenuAsClose } from '../../store/modules/menu/action';

export default function Header() {
  const dispatch = useDispatch();
  const menuStatus = useSelector(state => state.menu.open);
  const metadataInfo = useSelector(state => state.metadatas.information);

  function handleMenu() {
    if (menuStatus) return dispatch(setMenuAsClose({ open: false }));

    return dispatch(setMenuAsOpen({ open: true }));
  }

  return (
    <HeaderComponent
      handleMenu={handleMenu}
      menuStatus={menuStatus}
      metadataInfo={metadataInfo}
      pageType="Ranking"
    />
  );
}
