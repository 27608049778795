import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BigMenu as BigMenuComponent } from '@nucleos/components';

import { setMenuAsClose, setActiveItem } from '../../store/modules/menu/action';

export default function BigMenu({ children }) {
  const dispatch = useDispatch();
  const menu = useSelector(state => state.menu);
  const metadataInfo = useSelector(state => state.metadatas.information);

  function handleMenuItemClick(item) {
    dispatch(setMenuAsClose({ open: false }));
    dispatch(setActiveItem({ item }));
  }

  return (
    <BigMenuComponent
      menu={menu}
      content={children}
      metadataInfo={metadataInfo}
      handleMenuItemClick={handleMenuItemClick}
      pageType="ranking"
    />
  );
}
