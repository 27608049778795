import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors, fonts } from '@nucleos/theme';

export const Container = styled(Link)`
  position: relative;

  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border-radius: 4px;
  border-left: 3px solid ${props => props.theme.primary};
  box-shadow: 0 1px 2px 0 rgba(0, 9, 3, 0.15);

  > .left {
    > p,
    > div > p {
      max-width: 240px;
    }

    > p {
      line-height: 1.27;
      color: ${colors.black};
      font-size: ${fonts.size_2}px;
    }

    > div {
      margin-top: 2px;

      display: flex;
      align-items: center;

      > p {
        margin-left: 2px;

        font-size: ${fonts.size_1}px;
        color: ${props => props.theme.primaryLighten};
      }
    }
  }

  > .right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    padding: 4px;
    min-width: 66px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background: ${props => props.theme.primary};

    > strong {
      text-align: center;
      color: ${colors.white};
      letter-spacing: 0.34px;
      font-size: ${fonts.size_3}px;
    }

    > p {
      color: ${colors.white};
      letter-spacing: 0.22px;
      font-size: ${fonts.size_0}px;
    }
  }
`;
