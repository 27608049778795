import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styles';

export default function Container({ children, isModalOpen }) {
  return <Wrapper modalOpen={isModalOpen}>{children}</Wrapper>;
}

Container.defaultProps = {
  isModalOpen: false,
};

Container.propTypes = {
  isModalOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
