import styled, { keyframes } from 'styled-components';
import { FiLoader } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin: 120px auto 0;
`;

const rotate = keyframes`
  from { transform: rotate(360deg) },
  to { transform: rotate(0deg) }
`;

export const Icon = styled(FiLoader)`
  animation: ${rotate} 4s linear infinite;
`;
