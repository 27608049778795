import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { history } from '@nucleos/services';

import { Container, Icon, Item, Logo, Menu } from './styles';

export default function Header({
  handleMenu,
  metadataInfo,
  menuStatus,
  pageType,
}) {
  return (
    <Container>
      <header>
        <Logo to="/">
          <img
            alt={`${metadataInfo.name} logo`}
            src={`${metadataInfo.assets_base}/img/logo.png`}
          />
        </Logo>

        <ul>
          {metadataInfo.menu_items &&
            metadataInfo.menu_items.map(item => (
              <Item
                key={item.name}
                active={item.name.toUpperCase() === pageType.toUpperCase()}
              >
                <a href={item.url}>{item.prettyName}</a>
              </Item>
            ))}
        </ul>

        <div>
          <Menu onClick={handleMenu} isOpen={menuStatus} />

          <h1>
            {(history.location.state && history.location.state.page) ||
              pageType}
          </h1>

          <Icon
            name="facebook"
            target="_blank"
            href={metadataInfo.socials && metadataInfo.socials.facebook}
          >
            <FaFacebookF size={15} color="#fff" />
          </Icon>

          <Icon
            name="instagram"
            target="_blank"
            href={metadataInfo.socials && metadataInfo.socials.instagram}
          >
            <FaInstagram size={15} color="#fff" />
          </Icon>
        </div>
      </header>
    </Container>
  );
}

Header.defaultProps = {
  logo: 'abvaq',
  pageType: 'Resultados',
};

Header.propTypes = {
  logo: PropTypes.string,
  pageType: PropTypes.string,
  menuitems: PropTypes.arrayOf(PropTypes.string).isRequired,
};
