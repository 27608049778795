import styled from 'styled-components';
import posed from 'react-pose';
import { tween, easing } from 'popmotion';

import { metrics, fonts } from '@nucleos/theme';

const AnimatedContainer = posed.div({
  isExpanded: {
    height: 500,
    transition: props =>
      tween({
        ...props,
        duration: 100,
        ease: easing.cubicBezier(0, 0.42, 0, 1),
      }),
  },
  isNormal: {
    height: 52 + 60,
    transition: props => tween({ ...props, duration: 100 }),
  },
  isNormalWithMenuOpen: {
    height: 52,
    transition: props => tween({ ...props, duration: 100 }),
  },
});

export const Container = styled(AnimatedContainer)`
  position: sticky;
  -webkit-backface-visibility: hidden;
  left: 0;
  bottom: 0;
  z-index: 2;

  height: 52px;
  width: calc(100% + 24px);
  margin-left: -12px;
  box-shadow: ${props =>
    props.isExpanded && '0 -1px 6px 0 rgba(0, 0, 0, 0.16)'};
  padding: 20px ${metrics.containerPadding + 4}px;

  display: flex;
  align-items: flex-start;
  justify-content: ${props => (props.isExpanded ? 'flex-start' : 'center')};

  overflow: ${props => (props.isExpanded ? 'scroll' : 'hidden')};
  transition: background-color height 0.3s;
  background-color: ${props =>
    props.isExpanded ? props.theme.white : props.theme.primary};

  &:before {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);

    width: 80px;
    height: 3px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    display: block;

    background-color: ${props => props.theme.primaryDarken};

    content: '';
  }

  > p {
    margin-left: ${metrics.margin}px;

    line-height: 21px;
    color: ${props =>
      props.isExpanded ? props.theme.primary : props.theme.white};
    letter-spacing: 0.85px;
    font-weight: bold;
    font-size: ${fonts.size_3}px;
  }

  > .content {
    width: 100%;

    transition: opacity 0.5s;
    opacity: ${props => (props.isExpanded ? '1' : '0')};
  }
`;
