import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'react-modal';

import { Container, Title, Table } from '@nucleos/components';

import { CloseButton, Content } from './styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform:
      window.innerWidth > 1440
        ? 'translate(-50%, -32%)'
        : 'translate(-50%, -50%)',

    height: 600,
    width: '100%',
    maxWidth: 1000,
    marginRight: '-50%',

    overflow: 'visible',
  },
};

export default function Modal({ status, closeModal, data, category }) {
  return (
    <ModalComponent
      isOpen={status}
      data-testid="modal"
      style={customStyles}
      onRequestClose={closeModal}
    >
      <Content>
        <Container>
          <Title
            title="Ranking desta etapa"
            subtitle={`Resultados - Categoria ${category}`}
          />

          <Table
            data={data}
            search={false}
            labels={['Nº', 'Class', 'Puxador', 'Pontos']}
          />
        </Container>
      </Content>

      <CloseButton onClick={closeModal}>Fechar</CloseButton>
    </ModalComponent>
  );
}

Modal.defaultProps = {
  status: false,
};

Modal.propTypes = {
  status: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
