import styled from 'styled-components';

import { fonts, metrics } from '@nucleos/theme';

import { colors } from '../../theme';

export const InformationDesktop = styled.div`
  padding: 8px 30px;
  margin-bottom: 32px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors.whiteGreen};

  > div {
    display: flex;

    > div {
      display: flex;
      flex-direction: column;

      > strong,
      > span {
        line-height: 19px;
        letter-spacing: -0.3px;
        font-size: ${fonts.size_2}px;
      }

      > strong {
        color: ${colors.black};
      }

      > span {
        color: ${colors.primaryLighten};
      }

      & + div {
        margin-left: 35px;
      }
    }
  }
`;

export const InformationMobile = styled.div`
  width: calc(100% + 12px);
  margin-right: -12px;
  margin-bottom: 16px;
  padding: 8px 0 8px 5px;
  border-top-left-radius: ${metrics.radius}px;
  border-bottom-left-radius: ${metrics.radius}px;
  border-left: 3px solid ${colors.primaryLighten};

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors.whiteGreen};

  > .left {
    > div {
      display: flex;
      align-items: flex-start;

      div + & {
        margin-top: 8px;
      }

      > i {
        height: 18px;
        margin-right: 4px;
      }

      > p {
        margin-top: 1px;
        padding-right: 12px;

        line-height: 14px;
        font-size: ${fonts.size_1}px;
        color: ${colors.primaryLighten};

        > strong {
          margin-right: 3px;
        }
      }
    }
  }

  > .right {
    min-width: 76px;
    height: 51px;
    padding: 10px 6px;
    border-top-left-radius: ${metrics.radius}px;
    border-bottom-left-radius: ${metrics.radius}px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: ${colors.whiteGreen};
    background: ${colors.primary};

    > strong {
      font-size: ${fonts.size_3}px;
    }

    > span {
      line-height: 12px;
      font-size: ${fonts.size_0}px;
    }
  }
`;
