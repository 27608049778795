import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

import { colors, metrics, fonts } from '@nucleos/theme';

export const Container = styled.div`
  height: 90px;
  padding: 0 ${metrics.containerPadding}px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors.black};

  > header {
    width: 100%;
    margin: 0 auto;
    max-width: ${metrics.maxWidth}px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lessThan('large')`
      flex-direction: row-reverse;
    `}

    > ul {
      margin-bottom: 10px;

      align-self: flex-end;
      display: flex;

      ${media.lessThan('large')`
        display: none;
      `}
    }

    > div {
      margin-bottom: 8px;

      align-self: flex-end;
      display: flex;

      ${media.lessThan('large')`
        height: 40px;

        flex-direction: column;

      `}

      > h1 {
        display: none;

        font-weight: 600;
        font-size: ${fonts.size_3}px;
        line-height: 1.24;
        color: ${colors.white};
        text-transform: uppercase;

        ${media.lessThan('large')`
          display: block;
        `}
      }

      > a {
        transition: 0.3s;

        &:hover {
          transform: scale(1.1);
        }

        & + a {
          margin-left: 16px;
        }
      }
    }
  }
`;

const line = css`
  width: 24px;
  height: 2px;

  display: block;

  background-color: ${colors.white} !important;
`;

export const Menu = styled.button`
  position: relative;

  border: 0;
  margin-top: 2px;
  margin-bottom: 24px;
  border-bottom: 2px solid white;

  display: flex;
  flex-direction: column;
  transition: .5s;

  ${line}

  ${props =>
    props.isOpen
      ? css`
          height: 0;
          border-bottom: 0;
          transform: rotate(-360deg);
        `
      : css`
          height: 2px;
          transform: rotate(0);
        `}

  ${media.greaterThan('large')`
    display: none;
  `}

  &:before,
  &:after {
    ${line}
    position: absolute;
    left: 0;

    content: '';
  }

  &:before {
    top: -6px;

    ${props =>
      props.isOpen
        ? css`
            top: 0;
            transform: rotate(45deg);
          `
        : css`
            top: -6px;
            transform: rotate(0);
          `};
  }

  &:after {
    top: 6px;

    ${props =>
      props.isOpen
        ? css`
            top: 0;
            transform: rotate(-45deg);
          `
        : css`
            top: 6px;
            transform: rotate(0);
          `};
  }
`;

export const Logo = styled(Link)`
  height: 53px;

  > img {
    height: 100%;
  }
`;

export const Item = styled.li`
  font-size: ${fonts.size_1}px;
  font-weight: normal;
  line-height: 1.31;
  letter-spacing: 0.52px;
  text-transform: uppercase;

  > a {
    transition: 0.3s;
    color: ${props => (props.active ? props.theme.secondary : colors.white)};

    &:hover {
      color: ${props => props.theme.secondary};
    }
  }

  & + li {
    margin-left: 16px;
  }
`;

export const Icon = styled.a`
  width: 25px;
  height: 25px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #3e5c9a;

  ${media.lessThan('large')`
    display: none;
  `}

  ${props =>
    props.name === 'facebook'
      ? css`
          background: #3e5c9a;
        `
      : css`
          background-image: linear-gradient(32deg, #f9ed32, #ee2a7b, #002aff);
        `}
`;
