import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { Container, Menu, MenuItem, Icon } from './styles';

function BigMenu({
  menu,
  content,
  isMobile,
  pageType,
  metadataInfo,
  handleMenuItemClick,
}) {
  return (
    <Container menuOpen={menu.open}>
      {isMobile && (
        <Menu>
          <ul>
            {metadataInfo.menu_items &&
              metadataInfo.menu_items.map(item => (
                <MenuItem
                  key={item.name}
                  isActive={item.name === pageType}
                  onClick={() => handleMenuItemClick(item.name)}
                >
                  <a href={item.url}>{item.prettyName}</a>
                </MenuItem>
              ))}
          </ul>

          <div className="socials">
            <Icon
              name="facebook"
              target="_blank"
              href={metadataInfo.socials && metadataInfo.socials.facebook}
            >
              <FaFacebookF size={20} color="#fff" />
            </Icon>
            <Icon
              name="instagram"
              target="_blank"
              href={metadataInfo.socials && metadataInfo.socials.instagram}
            >
              <FaInstagram size={20} color="#fff" />
            </Icon>
          </div>
        </Menu>
      )}

      {content}
    </Container>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

BigMenu.defaultProps = {
  pageType: 'resultados',
};

BigMenu.propTypes = {
  content: PropTypes.element.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pageType: PropTypes.string,
};

export default withSizes(mapSizesToProps)(BigMenu);
