import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';

import { Card } from '@nucleos/components';
import { history } from '@nucleos/services';

import { Container, TableItem, TableLabel, Input } from './styles';

function Table({ data, card, labels, links, isMobile, linkType, search }) {
  const [rows, setRows] = useState([{}]);
  const [searchRows, setSearchRows] = useState([{}]);

  useEffect(() => {
    if (data) {
      setRows(data);
      setSearchRows(data);
    }
  }, [data]);

  function handleTextChange(event) {
    const searchString = String(event.target.value).toUpperCase();

    const filteredRows = rows.filter(row => {
      const itemsToUpper = Object.keys(row).map(item => {
        return String(row[item]).toUpperCase();
      });

      const isMatching = itemsToUpper.filter(itemUpper => {
        return itemUpper.indexOf(searchString) > -1;
      });

      return isMatching.length > 0;
    });

    setSearchRows(filteredRows);
  }

  function textHasNumberCharacter(text) {
    return String(text).includes('º') && text.length <= 7;
  }

  function isNumber(label) {
    if (textHasNumberCharacter(label)) return true;

    if (typeof label === 'number') return true;

    if (label === 'Class') return true;
    if (label === 'Pontos') return true;
    if (label === 'Nº') return true;

    return false;
  }

  function handleWeekDayFormat(weekDay) {
    return weekDay.split('-')[0];
  }

  function isTotalScore(label) {
    if (label.toLowerCase() === 'points') return true;

    return false;
  }

  function renderTable() {
    return (
      <table>
        <thead>
          <tr>
            {labels.map(label => (
              <TableLabel isNumber={isNumber(label)} key={label}>
                {label}
              </TableLabel>
            ))}
          </tr>
        </thead>

        <tbody>
          {searchRows &&
            searchRows.map((row, index) => (
              <tr
                key={index}
                data-testid="rows"
                onClick={() =>
                  links &&
                  history.push({
                    pathname: `/${linkType}/${row.id}/${row.year}`,
                    state: {
                      title: row.event,
                      state: row.place,
                      city: row.city,
                    },
                  })
                }
              >
                {Object.keys(row).map(
                  item =>
                    item !== 'id' && item !== 'year' && (
                      <TableItem
                        key={item}
                        isNumber={isNumber(row[item])}
                        isTotalScore={isTotalScore(item)}
                      >
                        {row[item]}
                      </TableItem>
                    )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  function renderCard() {
    return (
      <>
        {searchRows &&
          searchRows.map((row, index) => (
            <Card
              key={index}
              id={row.id}
              day={row.day}
              weekDay={
                (row.weekDay && handleWeekDayFormat(row.weekDay)) || 'Sem data'
              }
              event={row.event}
              place={row.place}
              city={row.city}
              state={row.state}
            />
          ))}
      </>
    );
  }

  return (
    <Container>
      {search && (
        <Input
          data-testid="search"
          placeholder="Pesquisar"
          onChange={event => handleTextChange(event)}
        />
      )}

      {isMobile && card ? renderCard() : renderTable()}

      {data.length === 0 && <p>Nada para listar.</p>}
    </Container>
  );
}

Table.defaultProps = {
  card: false,
  links: false,
  search: true,
  linkType: 'resultados',
};

Table.propTypes = {
  // data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  card: PropTypes.bool,
  links: PropTypes.bool,
  search: PropTypes.bool,
  linkType: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(Table);
