import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import './theme/styles.css';
import './config/Reactotron';

import { Global } from '@nucleos/theme';
import { history } from '@nucleos/services';

import Routes from './routes';
import { store } from './store';
import { colors } from './theme';
import { Header, BigMenu } from './components';

export default function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={colors}>
          <>
            <Global primary={colors.primary} />

            <BigMenu>
              <div className="body">
                <Header />

                <Routes />
              </div>
            </BigMenu>
          </>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}
