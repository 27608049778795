import styled from 'styled-components';
import { darken } from 'polished';
import media from 'styled-media-query';

import { colors, metrics, fonts } from '@nucleos/theme';

export const Container = styled.ul`
  min-width: 160px;
  margin: 38px 40px 58px 0;

  ${media.lessThan('large')`
    width: calc(100% + 24px);
    padding: 0 12px;
    margin: 0 0 8px -12px;

    display: flex;

    overflow-x: scroll;
    scroll-behavior: smooth;
  `}
`;

export const Item = styled.li`
  height: 40px;
  padding: 0 16px;
  margin-bottom: 2px;

  cursor: pointer;
  font-weight: 500;
  line-height: 40px;
  white-space: nowrap;
  color: ${colors.black};
  font-family: GoogleSans-Medium, GoogleSans-Regular;
  font-size: ${fonts.size_1}px;
  background-color: ${props =>
    props.active ? props.theme.secondary : props.theme.secondaryLighten};

  ${media.lessThan('large')`
    margin-bottom: 0;
    padding: 0 8px;

    letter-spacing: 0.26px;
    font-size: ${fonts.size_1}px;

    & + & {
      margin-left: 1px;
    }
  `}

  &:hover {
    background-color: ${props =>
      props.active
        ? darken(0.1, props.theme.secondary)
        : darken(0.1, props.theme.secondaryLighten)};
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    ${media.lessThan('large')`
      border-top-right-radius: 0;
      border-top-left-radius: ${metrics.radius}px;
      border-bottom-left-radius: ${metrics.radius}px;
    `}
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    ${media.lessThan('large')`
      border-bottom-left-radius: 0;
      border-top-right-radius: ${metrics.radius}px;
      border-bottom-right-radius: ${metrics.radius}px;
    `}
  }
`;
