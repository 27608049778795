import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { metrics } from '@nucleos/theme';

export const Wrapper = styled.section`
  position: relative;

  width: 100%;
  margin: 40px auto 10px;
  max-width: ${metrics.maxWidth}px;

  ${media.lessThan('large')`
    max-width: unset;
    margin: 20px 0 0;
    padding: 0 ${metrics.containerPadding}px;

    ${props =>
      props.modalOpen &&
      css`
        &:before {
          position: absolute;
          top: -40px;
          left: 0;
          z-index: 1;

          width: 100vw;
          height: 100vh;

          display: block;

          background: rgba(0, 0, 0, 0.5);
          content: '';
        }
      `}
  `}
`;
