import styled from 'styled-components';
import Select from 'react-select';
import media from 'styled-media-query';

import { colors, fonts } from '@nucleos/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;

  > label {
    margin-right: 4px;

    color: ${colors.black};
    font-size: ${fonts.size_2}px;

    ${media.lessThan('large')`
      display: none;
    `}
  }
`;

export const SelectLib = styled(Select).attrs(props => ({
  styles: {
    control: () => ({
      display: 'flex',
      height: props.isMobile ? 40 : 30,
    }),

    dropdownIndicator: () => ({
      display: 'flex',
      alignItems: 'center',

      ':focus': {
        backgroundColor: colors.grey,
      },
    }),

    input: () => ({
      color: colors.white,
      maxWidth: 200,
    }),

    singleValue: () => ({
      color: '#fff',
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    option: () => ({
      padding: 8,
      color: props.theme.primary,
      cursor: 'pointer',
    }),
  },
}))`
  min-width: 100px;
  height: 30px;
  padding: 0 6px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  font-size: ${fonts.size_1}px;
  color: ${colors.white};
  background: ${props => props.theme.primary};

  ${media.lessThan('large')`
    height: 40px;
  `}
`;
