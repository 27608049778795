import styled from 'styled-components';

import { colors, fonts } from '@nucleos/theme';

export const Content = styled.div`
  width: 100%;
  height: 100%;

  overflow: scroll;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -20px;
  right: -4px;

  height: 40px;
  padding: 0 20px;
  border-radius: 24px;
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  line-height: 40px;
  color: ${colors.white};
  font-size: ${fonts.size_2}px;
  background: ${colors.danger};
`;
