import styled from 'styled-components';
import media from 'styled-media-query';

import { colors, fonts } from '@nucleos/theme';

export const Container = styled.div`
  position: relative;

  ${media.lessThan('large')`
    width: 100%;
  `}

  > input {
    width: 240px;
    height: 30px;
    padding: 0 30px;
    border: 0;
    border-radius: 3px;

    font-size: ${fonts.size_2}px;

    color: ${props => props.colors};
    background-color: ${colors.whiteVariant2};

    ${media.lessThan('large')`
      width: 100%;
      height: 40px;
    `}
  }

  > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  }
`;
