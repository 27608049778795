/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';

import { Container, SelectLib } from './styles';

function Select({ options, name, type, onChange, isMobile }) {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    function handleSelectType() {
      if (type === 'month') {
        return setSelectedValue({
          label: 'Todos',
          monthNumber: 'all',
          paramNumber: 'all',
          value: 'Todos',
        });
      }
      console.log(options[0]);
      if (type === 'year') {
        // return setSelectedValue({
        //   label: new Date().getFullYear(),
        //   value: new Date().getFullYear(),
        // });
        return setSelectedValue({
          label: options[0].value,
          value: options[0].value,
        });
      }

      return false;
    }

    handleSelectType();
  }, [options, type]);

  useEffect(() => {
    onChange(selectedValue);
  }, [onChange, selectedValue]);

  function handleSelectChange(selectedOption) {
    setSelectedValue(selectedOption);
  }

  return (
    <Container>
      <label>{name}:</label>

      <SelectLib
        isMobile={isMobile}
        options={options}
        value={selectedValue}
        onChange={event => handleSelectChange(event)}
      />
    </Container>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(Select);
