import { colors } from '@nucleos/theme';

export default {
  primary: '#366045',
  primaryDarken: '#21472E',
  primaryLighten: '#596C5E',

  secondary: '#FFCC2B',
  secondaryDarken: '#FF9C2B',
  secondaryLighten: 'rgba(255, 204, 43, 0.47)',

  ...colors,
};
