import styled from 'styled-components';
import media from 'styled-media-query';

import { fonts } from '@nucleos/theme';
import { colors } from '../../theme';

export const Content = styled.div`
  display: flex;

  ${media.lessThan('large')`
    padding-top: 52px;
  `}

  > .left {
    > .ranking {
      display: none;

      > p {
        width: 160px;
        margin-bottom: 7px;

        line-height: 17px;
        text-align: center;
        color: ${colors.black};
        font-size: ${fonts.size_1}px;
      }

      > button {
        height: 40px;
        border: 0;
        border-radius: 8px;
        padding: 0 34px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.85px;
        font-size: ${fonts.size_3}px;
        color: ${colors.whiteGreen};
        background: ${colors.primary};

        > svg {
          margin-right: 4px;
        }
      }
    }
  }

  > .right {
    width: 100%;
  }

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;

export const Page = styled.div`
  position: relative;

  ${media.lessThan('large')`
    margin-top: 0;
  `}

  .filters {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;

    ${media.lessThan('large')`
      top: -100px;
      right: 0;
    `}

    > div {
      ${media.lessThan('large')`
        margin-left: auto;
      `}
    }

    > div + div {
      margin-left: 16px;
    }
  }
`;
