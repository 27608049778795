export default {
  black: '#000903',
  grey: '#999999 ',
  greyest: '#eee',
  white: '#fff',
  whiteGreen: '#f9fafb',
  whiteVariant1: '#F3F3F3',
  whiteVariant2: '#F3F5E7',
  danger: '#f07d7d',
  success: 'hsl(141, 71%, 48%)',
  warning: 'hsl(48, 100%, 67%)'
};