import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { fonts, colors, metrics } from '@nucleos/theme';

export const Container = styled.div`
  ${media.lessThan('large')`
    position: relative;

    display: flex;
    justify-content: space-between;

    &:before {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);

        width: 230px;
        height: 485px;
        opacity: 0.5;
        border-radius: ${metrics.border};
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        display: block;

        content: "";
        background: ${props => props.theme.primaryDarken}
      }

    > ul {
      position: relative;
      left: ${props => (props.menuOpen ? 0 : -200)}px;

      width: 50%;
      height: 100vh;
      padding-left: 32px;

      display: flex;
      justify-content: center;
      flex-direction: column;

      transition: .5s;
    }

    > .body {
      position: absolute;
      top: 0;
      right: 0;

      border-radius: 0;
      width: 100%;
      height: auto;
      min-height: 100vh;
      box-shadow: unset;

      transition: .3s;
      background: #FFF;

      ${props =>
        props.menuOpen
          ? css`
              top: 50vh;
              right: -155px;
              border-radius: 4px;
              transform: translateY(-50%) scale(0.85);

              height: 660px;
              min-height: unset;
              overflow: auto;
              box-shadow: -12px 4px 20px rgba(0, 0, 0, 0.2);
            `
          : css`
              top: 0;
              right: 0;

              border-radius: 0;
              transform: translateY(0) scale(1);

              width: 100%;
              height: 660px;
              min-height: 100vh;
              box-shadow: unset;

              overflow: auto;
            `}
    }
  `}
`;

export const Menu = styled.div`
  width: 45%;
  height: 100vh;
  padding-left: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > .socials {
    display: flex;
  }
`;

export const MenuItem = styled.li`
  margin-bottom: 24px;

  > a {
    line-height: 1.31;
    letter-spacing: 0.52px;
    text-transform: uppercase;
    color: ${colors.whiteGreen};
    font-size: ${fonts.size_1}px;
    color: ${props => (props.isActive ? props.theme.secondary : colors.white)};
  }
`;

export const Icon = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.name === 'facebook'
      ? css`
          background: #3e5c9a;
        `
      : css`
          background-image: linear-gradient(32deg, #f9ed32, #ee2a7b, #002aff);
        `}

  & + & {
    margin-left: 30px;
  }
`;
