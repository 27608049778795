import React from 'react';
import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';

import { Container } from './styles';

export default function Input(props) {
  const { colors } = props;

  return (
    <Container>
      <input {...props} type="text" />

      <FiSearch size={16} color={colors} />
    </Container>
  );
}

Input.defaultProps ={
  colors: '#333'
}

Input.propTypes = {
  colors: PropTypes.string,
};
