import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { useSelector } from 'react-redux';
import { IoIosPodium, IoMdCalendar, IoMdTrophy } from 'react-icons/io';

import { api } from '@nucleos/services';
import { date } from '@nucleos/functions';
import { Container, Helmet, Title, Table, Loader } from '@nucleos/components';

import { colors } from '../../theme';

import { InformationDesktop, InformationMobile } from './styles';

const labels = ['Data', 'Categoria', 'Evento', 'Pontos'];

function Details({ isMobile, match }) {
  const information = useSelector(store => store.metadatas.information);

  const [totalInformation, setTotalInformation] = useState({
    competitorName: '',
    events: 0,
    points: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const competitorId = match.params.id;
  const requestYear = match.params.year;

  useEffect(() => {
    async function fetchCompetitorInfo() {
      try {
        setIsLoading(true);

        const { data } = await api.get(
          // `competitors/${competitorId}/classifications`
          `competitors/${competitorId}/classifications/${requestYear}`
        );

        const competitorName =
          data[0].classifications_has_competitor[0].competitor.name;
        const events = data.length;
        const points = data.reduce((currentValue, nextValue) => {
          return currentValue + Number(nextValue.points);
        }, 0);

        const eventsRows = data
          .map(event => ({
            date:
              (event.stage.start_date &&
                date.normalMaskedDate(event.stage.start_date)) ||
              'Sem data',
            title: event.classification_category.title,
            event: event.stage.title,
            points: Number(event.points),
          }))
          .reverse();

        setRows(eventsRows);

        setTotalInformation({
          events,
          points,
          competitorName,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchCompetitorInfo();
  }, [competitorId, match, match.params, requestYear]);

  function renderDesktop() {
    return (
      <InformationDesktop>
        <div className="left">
          <div>
            <strong>Período</strong>
            <span>{requestYear}</span>
          </div>

          <div>
            <strong>Eventos</strong>
            <span>{totalInformation.events}</span>
          </div>

          <div>
            <strong>Pontos</strong>
            <span>{totalInformation.points}</span>
          </div>
        </div>
      </InformationDesktop>
    );
  }

  function renderMobile() {
    return (
      <InformationMobile>
        <div className="left">
          <div>
            <i>
              <IoMdCalendar
                size={18}
                className="place"
                color={colors.primary}
              />
            </i>

            <p>
              <strong>Período:</strong>
              <span>{requestYear}</span>
            </p>
          </div>

          <div>
            <i>
              <IoMdTrophy size={18} className="place" color={colors.primary} />
            </i>

            <p>
              <strong>Eventos:</strong>
              <span>{totalInformation.events}</span>
            </p>
          </div>

          <div>
            <i>
              <IoIosPodium size={18} color={colors.primary} />
            </i>

            <p>
              <strong>Pontos:</strong>
              <span>{totalInformation.points}</span>
            </p>
          </div>
        </div>
      </InformationMobile>
    );
  }

  return (
    <Container>
      <>
        <Helmet
          title={`${String(information.name).toUpperCase()} - Ranking Detalhes`}
          information={information.metatags}
        />

        {isLoading ? (
          <Loader
            type="ThreeDots"
            color={colors.secondary}
            height={80}
            width={80}
          />
        ) : (
          <>
            <Title
              backBtn
              title={totalInformation.competitorName}
              subtitle="Ranking - Participação em Eventos"
            />

            {isMobile ? renderMobile() : renderDesktop()}

            <Table data={rows} labels={labels} />
          </>
        )}
      </>
    </Container>
  );
}

Details.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  match: PropTypes.shape().isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1170,
});

export default withSizes(mapSizesToProps)(Details);
