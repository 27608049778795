import styled, { css } from 'styled-components';
import { darken } from 'polished';
import media from 'styled-media-query';

import { colors, fonts } from '@nucleos/theme';
import InputComponent from '../Input';

export const Container = styled.div`
  width: 100%;
  min-height: 54vh;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div {
    margin-bottom: 8px;
  }

  > table {
    width: 100%;
    border-radius: 2px;
    border-spacing: 0 !important;

    overflow: hidden;

    > thead {
      border: 0;
      padding: 7px 0;

      background: ${props => props.theme.primary};
    }

    > tbody {
      > tr {
        height: 32px;
        margin-bottom: 1px;

        transition: 0.3s;
        cursor: pointer;
        background: ${colors.whiteGreen};

        &:hover {
          background: ${darken(0.012, colors.white)};
        }

        &:nth-child(2n) {
          background: ${colors.whiteVariant1};

          &:hover {
            background: ${darken(0.012, colors.whiteVariant1)};
          }
        }
      }
    }
  }

  > p {
    margin: 10px auto 0;

    display: block;

    text-align: center;

    word-break: keep-all;
    color: ${colors.black};
    font-size: ${fonts.size_1}px;
  }
`;

export const TableLabel = styled.th`
  padding-left: ${props => (props.isNumber ? 0 : 7)}px;

  ${props =>
    props.isNumber &&
    css`
      width: 40px;
    `}

  line-height: 30px;
  color: ${colors.white};
  font-size: ${fonts.size_1}px;
  font-family: GoogleSans-Bold, sans-serif;
  text-align: ${props => (props.isNumber ? 'center' : 'left')} !important;

  ${media.lessThan('large')`
    line-height: 17px;
  `}
`;

export const TableItem = styled.td`
  max-width: ${props => (props.isNumber ? '70px' : 'auto')};
  padding-left: ${props => (props.isNumber ? 0 : 8)}px;

  line-height: 1.27;
  color: ${colors.black};
  font-size: ${fonts.size_2}px;
  text-align: ${props => (props.isNumber ? 'center' : 'left')} !important;
  background: ${props => (props.isTotalScore ? 'rgba(73, 94, 79, 0.08);' : '')};

  ${media.lessThan('large')`
    max-width: ${props => (props.isNumber ? '30px' : 'auto')};
    padding: 8px;

    font-size: ${fonts.size_0}px;
  `};
`;

export const Input = styled(InputComponent).attrs(props => ({
  colors: props.theme.primaryLighten,
}))``;
