import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Helmet,
  Container,
  Title,
  Table,
  TableAction,
  Select,
  Loader,
} from '@nucleos/components';
import { GoogleAnalytics } from '@nucleos/functions';
import { api } from '@nucleos/services';

import { colors } from '../../theme';
import { requestItems } from '../../store/modules/menu/action';
import { metadataRequest } from '../../store/modules/metadatas/action';

import { Content, Page } from './styles';

const labels = ['Nº', 'Class', 'Puxador', 'Pontos'];

export default function Main() {
  const dispatch = useDispatch();
  const metadatas = useSelector(state => state.metadatas.information);

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isParamsChange, setIsParamsChange] = useState(false);
  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState('2019');
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({
    id: 1,
    title: 'Profissional',
  });

  useEffect(() => {
    if (metadatas) GoogleAnalytics(metadatas.analytics_uid);
  }, [metadatas, metadatas.analytics_uid]);

  useEffect(() => {
    dispatch(metadataRequest());
    dispatch(requestItems(metadatas.client_id));

    async function fetchYears() {
      if (metadatas.client_id) {
        setIsLoading(true);

        try {
          const response = await api.get('stages/years', {
            params: {
              client_id: metadatas.client_id,
            },
          });
          // console.log(response);
          const formatedYears = response.data
            .map(yearItem => ({
              value: yearItem,
              label: yearItem,
            }))
            .reverse();

          // setYears([{ value: 'Todos', label: 'Todos' }, ...formatedYears]);
          setYears([...formatedYears]);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchYears();
  }, [dispatch, metadatas.client_id]);

  useEffect(() => {
    let params = {
      client_id: metadatas.client_id,
    };
    // console.log(metadatas);
    if (isParamsChange && activeYear !== 'Todos') {
      params = {
        client_id: metadatas.client_id,
        start_date: `${activeYear}-01-01`,
        finish_date: `${activeYear}-12-31`,
      };
    }

    async function fetchRanking(category_id = 1) {
      try {
        const [classificationResponse, categoriesResponse] = await Promise.all([
          api.get('ranking', {
            params: {
              ...params,
              classification_category_id: category_id,
            },
          }),
          api.get('classifications/categories'),
        ]);

        const classifications = classificationResponse.data.map(
          (competitor, index) => ({
            id: competitor.classifications_has_competitor[0].competitor.id,
            position: index + 1,
            class: `${competitor.classification}º`,
            puxador:
              competitor.classifications_has_competitor[0].competitor.name,
            points: competitor.points,
            year: activeYear,
          })
        );
        // console.log(classifications);
        setRows(classifications);
        setCategories(categoriesResponse.data);
      } catch (err) {
        console.log(err.message);
      }
    }

    fetchRanking(activeCategory.id);
  }, [
    activeCategory.id,
    activeYear,
    isParamsChange,
    metadatas,
    metadatas.client_id,
  ]);

  function handleChangeYear(selectedYear) {
    if (selectedYear && Number(activeYear) !== Number(selectedYear.value)) {
      setIsParamsChange(true);
      setActiveYear(selectedYear.value);
    }
  }

  return (
    <>
      {metadatas.name && (
        <Helmet
          title={`${metadatas.name.toUpperCase()} - Ranking`}
          information={metadatas.metatags}
        />
      )}
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color={colors.secondary}
          height={80}
          width={80}
        />
      ) : (
        <Container>
          <Title
            suspend
            title="Ranking"
            subtitle={`Categoria ${activeCategory.title}`}
          />

          <Content>
            <div className="left">
              <TableAction
                categories={categories}
                activeCategory={activeCategory.title}
                changeActiveCategory={setActiveCategory}
              />
            </div>

            <div className="right">
              <Page>
                <>
                  <div className="filters">
                    <Select
                      name="Ano"
                      type="year"
                      options={years}
                      onChange={handleChangeYear}
                    />
                  </div>

                  <Table data={rows} labels={labels} links linkType="ranking" />
                </>
              </Page>
            </div>
          </Content>
        </Container>
      )}
    </>
  );
}
